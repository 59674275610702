import React from "react";
import {Form, Segment, Message, Button} from "semantic-ui-react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import userStore from "../../stores/userStore";
import history from "../../helper/browserHistory";

@observer
class Login extends React.Component {

    @observable loginError = {
        username: false,
        password: false,
        logIn: false
    };

    constructor(props) {
        super(props);
this.state={
    user: {
        username: "",
        password: ""
    },
    loading: false
}
        this.login = this.login.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    resetInputFields() {
        let user = this.state.user;
        user.username = "";
        user.password = "";
        this.setState({...this.state, user: user})
    }


    login() {
       this.setState({...this.state, loading: true});
        this.loginError.logIn = false;

        if (this.state.user.username === "") {
            this.setState({...this.state, loading: false});
            this.loginError.username = true;
        }
        else if (this.state.user.password === "") {
            this.setState({...this.state, loading: false});
            this.loginError.password = true;
        }
        else {
            userStore.authenticateUser(this.state.user).then(res=>{
                this.setState({...this.state, loading: false});
                history.go(0)
            });
        }

    }

    handleChange(prop, e) {
        this.loginError.username = false;
        this.loginError.password = false;
        let user = this.state.user;
        user[prop] = e.target.value
        this.setState({...this.state, user})
    }


    render() {

        return (
            <div>
                <Segment style={{display: "table", margin: "0 auto"}} compact loading={this.state.loading}>
                    <Form size="large" error={this.loginError.logIn}>
                        <Form.Field>
                            <Form.Input value={this.state.user.username}
                                        error={this.loginError.username}
                                        onChange={this.handleChange.bind(this, "username")}
                                        label='Username' type='Username'/>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input value={this.state.user.password}
                                        error={this.loginError.password}
                                        onChange={this.handleChange.bind(this, "password")}
                                        label='Passwort' type='Password'/>
                        </Form.Field>

                        <Message
                            error
                            header='Login fehlgeschlagen'
                            content='Eingabe überprüfen und erneut versuchen.'
                        />
                        <Button onClick={this.login} type='submit'>Log in</Button>
                    </Form>
                </Segment>
            </div>
        );
    }
}

export default Login